import React, { useEffect } from "react";

import { RemoveSplashScreen } from "js/core/SplashScreen";

export function Stub() {
    useEffect(() => {
        RemoveSplashScreen();
    }, []);

    return <div id="stub">Stub</div>;
}
