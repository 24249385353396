import React, { useState, useRef, useEffect } from "react";
import Cookies from "js-cookie";
import firebase from "firebase/compat/app";

import { LoadingPage } from "js/react/components/LoadingPage";
import ErrorMessage from "js/app/ErrorMessage";
import { serverContext } from "js/config";
import getLogger, { LogGroup } from "js/core/logger";
import { RemoveSplashScreen } from "js/core/SplashScreen";
import sanitizeRelativeUrl from "common/utils/sanitizeRelativeUrl";
import { auth } from "js/firebase/auth";

const logger = getLogger(LogGroup.AUTH);

export function ConsumeCustomToken() {
    const [status, setStatus] = useState<"pending" | "succeeded" | "failed">(serverContext.customToken ? "pending" : "failed");
    const unsubscribeRef = useRef<() => void>();

    const handleAuthStateChanged = (user: firebase.User | null) => {
        unsubscribeRef.current?.();
        unsubscribeRef.current = undefined;

        (async () => {
            try {
                if (user) {
                    logger.info("[ConsumeCustomToken] User is logged in, logging out..");
                    // We'll sign out from all fb apps
                    for (const app of firebase.apps) {
                        await auth(app).signOut();
                    }
                }

                logger.info("[ConsumeCustomToken] Signing in with custom token");
                await auth().signInWithCustomToken(serverContext.customToken);

                Cookies.set("logged-in", "true");

                logger.info("[ConsumeCustomToken] Signed in with custom token");

                const urlParams = new URLSearchParams(window.location.search);
                if (urlParams.has("stay")) {
                    logger.info("[ConsumeCustomToken] Staying on the page..");
                    setStatus("succeeded");
                    return;
                }

                const continueUrl = sanitizeRelativeUrl(urlParams.get("continue") ?? "/");
                logger.info(`[ConsumeCustomToken] Redirecting to ${continueUrl}..`);
                window.location.href = continueUrl;
            } catch (err) {
                logger.error(err, "[ConsumeCustomToken] Failed to sign in with custom token");
                setStatus("failed");
            }
        })();
    };

    const handleAuthError = (error: firebase.auth.Error) => {
        unsubscribeRef.current?.();
        unsubscribeRef.current = undefined;

        logger.error(new Error(error.message), "[ConsumeCustomToken] Firebase auth error", error);
        setStatus("failed");
    };

    useEffect(() => {
        RemoveSplashScreen();

        if (status === "failed") {
            // No token in server context
            return;
        }

        logger.info("[ConsumeCustomToken] Subscribing to auth state changes");
        unsubscribeRef.current = auth().onAuthStateChanged(handleAuthStateChanged, handleAuthError);

        return () => {
            unsubscribeRef.current?.();
        };
    }, []);

    if (status === "pending") {
        return <LoadingPage />;
    }

    if (status === "succeeded") {
        return <div id="success-message">Signed in</div>;
    }

    return <ErrorMessage message={"Sorry, we could not authenticate you."} />;
}
